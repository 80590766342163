<template>
    <div class="page">
        <div class="title">区块链查询分布</div>
        <div class="chatContainer" element-loading-background="rgba(0, 0, 0, 0)" element-loading-text="加载中" v-loading="loading">
            <div id="chart" ref="chart"></div>
            <div v-if="msgShow" class="msgInfo" :style="{'left':left+'px','top':top+'px'}">
                <div class="t">
                    <div>{{lineData.properties.name}}</div>
                    <div class="el-icon-circle-close" @click="msgShow=false"></div>
                </div>
                <div class="v">累计查询量：{{lineData.value}}</div>
                <div id="chart1" ref="chart1"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import * as echarts from 'echarts';
    import worldData from "./json/china.json";
    echarts.registerMap("china", worldData);
    import axios from '@/axios/index'
    import axiosCB from '@/axios/indexCB'
    import * as moment from 'moment'
    import * as math from 'mathjs'
    import { resolve } from 'mathjs';
    moment.locale("zh-cn")
    export default {
        name:"page5",
        components: {},
        data() {
            return {
                loading: true,
                msgShow: false,
                chart: null,
                left: 0,
                top: 0,
                lineData: {}
            }
        },
        methods: {
            async setLineCharts(data) {
                this.lineData = data
                this.msgShow = true
                await new Promise(resolve => {
                    this.$nextTick(() => resolve())
                })
                this.chart1 = echarts.init(this.$refs.chart1);
                let seriesData = []
                let xAxisData = []
                for (let i = 0; i < data.list.length; i++) {
                    const item = data.list[i];
                    seriesData.push(item.count)
                    xAxisData.push(`${item.month}月\n${item.year}`)
                }
                this.chart1.setOption({
                    grid: {
                        width: '85%',
                        height: '70%',
                        // left: '0%',
                        // right: '0%',
                        // bottom: '0%',
                        top: '12%',
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xAxisData,
                        axisLine: {
                            lineStyle: {
                                color: "rgba(184, 190, 190, 1)",
                            },
                            show: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: false //去掉折线图中的横线
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(184, 190, 190, 1)",
                            },
                            show: true
                        }
                    },
                    series: [
                        {
                            smooth: true,
                            showSymbol: false,
                            data: seriesData,
                            type: 'line',
                            color: "rgba(30, 205, 164, 1)",
                            areaStyle: {
                                color: "rgba(30, 205, 164, 0.1)"
                            }
                        }
                    ]
                })
            }
        },
        async mounted() {
            this.chart = echarts.init(this.$refs.chart);
            let response = await axiosCB.request({
                url: "/statistic",
                method: "GET"
            })
            let { list } = response.data
            let visualMapMax = 0
            let seriesData = []
            let pointData = []
            for (let index = 0; index < worldData.features.length; index++) {
                const item = worldData.features[index];
                let tempList = JSON.parse(JSON.stringify(list))
                item.value = 0
                for (let i = 0; i < tempList.length; i++) {
                    const item1 = tempList[i];
                    item1.count += (item1.count * item.percent * 100)
                    item.value += item1.count
                }
                item.value = parseInt(item.value)

                if (visualMapMax < item.value) visualMapMax = item.value
                item.list = tempList
                seriesData.push({ name: item.properties.name, value: item.value })
                pointData.push({
                    name: item.properties.name,
                    value: item.properties.center
                })
            }

            let series = [
                {
                    name: '区块链查询分布',
                    type: 'map',
                    map: 'china',
                    // aspectScale: 0.8,
                    zoom: 1.4, //当前视角的缩放比例
                    top: '25%',
                    // width: '100%',
                    // height: '100%',
                    data: seriesData,
                    label: {
                        show: true,
                        color: '#666666',
                        // emphasis: { //选中颜色
                        //     show: true,
                        //     color: '#666666'
                        // }
                    },
                    itemStyle: {
                        normal: {
                            borderWidth: 1, //边际线大小
                            // borderColor: 'red', //边界线颜色
                            areaColor: '#F3FDF9', //默认区域颜色
                            borderColor: '#D8DEDE',
                        },
                        // emphasis: {//选中颜色
                        //     show: true,
                        //     borderWidth: 1, //边际线大小
                        //     areaColor: '#F3FDF9', //鼠标滑过区域颜色
                        //     borderColor: '#D8DEDE',
                        //     label: {
                        //         show: true,
                        //         textStyle: {
                        //             color: '#666666'
                        //         }
                        //     }
                        // }
                    },
                },
                {
                    tooltip: {
                        show: false
                    },
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    zlevel: 1,
                    symbolSize: 15,
                    symbol: 'image://' + require('@/assets/image/gps1.png'),
                    data: pointData,
                }
            ];
            this.chart.setOption({
                visualMap: {
                    min: 0,
                    max: visualMapMax,
                    text: ['累计查询量'],
                    realtime: false,
                    calculable: true,
                    inRange: {
                        color: ['#F3FDF9', '#4BD79B']
                    }
                },
                tooltip: {
                    show: true
                },
                // grid: {
                //     width: '100%',
                //     height: '100%',
                //     // left: '0%',
                //     // right: '0%',
                //     // bottom: '0%',
                //     // top:'10%',
                //     containLabel: true
                // },
                geo: {
                    zoom: 1.4, //当前视角的缩放比例
                    top: '25%',
                    // width: '100%',
                    // height: '100%',
                    type: 'map',
                    map: 'china',
                    // aspectScale: 0.8, // 改变地图的长宽比  使用这个时候不能设置高度 否则无效
                    itemStyle: {
                        normal: {
                            borderWidth: 1, //边际线大小
                            // borderColor: 'red', //边界线颜色
                            areaColor: '#F0F3F2', //默认区域颜色
                            borderColor: '#D8DEDE'
                        },
                    }
                },
                series
            })
            this.loading = false
            this.chart.on('click', (params) => { //点击事件
                if (params.componentType !== 'series' || params.componentSubType !== "scatter") return
                this.left = params.event.offsetX
                this.top = params.event.offsetY
                this.setLineCharts(worldData.features[params.dataIndex])
            });

        },
        destroyed() {
            if (this.chart) this.chart.dispose()
        }

    }
</script>

<style scoped lang="less">
    .page {
        height: calc(100vh - 128px);
        display: flex;
        // border: 1px solid red;
        flex-direction: column;
        box-sizing: border-box;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        margin-top: 32px;
        flex-shrink: 0;
    }

    .chatContainer {
        position: relative;
        width: 100%;
        flex: auto;
        box-sizing: border-box;
        // border: 1px solid blue;

        #chart {
            width: 100%;
            height: 100%
        }

        .msgInfo {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            padding: 24px;
            box-sizing: border-box;
            width: 450px;
            height: 302px;
            background: #FFFFFF;
            box-shadow: 0px 3px 16px 1px rgba(7, 50, 43, 0.2);
            border-radius: 4px 4px 4px 4px;
            display: flex;
            flex-direction: column;

            .t {
                font-size: 18px;
                font-weight: 600;
                color: #1ECDA4;
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -6px;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(90deg, #1ECDA4 0%, rgba(30, 205, 164, 0) 100%);
                }

                .el-icon-circle-close {
                    color: #c3c3c3;
                    cursor: pointer;
                }
            }

            .v {
                margin-top: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;

            }

            #chart1 {
                box-sizing: border-box;
                width: 100%;
                flex: auto;
            }

        }
    }
</style>